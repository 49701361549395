.history-header {
  @include boxPadding(15px, 15px);
  margin: 0;

  color: $textColor;
  font-size: 14px;
  font-weight: $baseFontRegular;
  background-color: $borderColor;

  a:link,
  a:visited {
    font-weight: $baseFontDemiBold;
    color: $textColor;
    text-decoration: underline;
  }

  a:hover,
  a:active {
    color: $hoverColor;
    text-decoration: underline;
  }

  .sep {
    @include separator($metaTextColor, 10px);
    height: 17px;
    padding: 0;
  }
}

.history-empty {
  @include boxPadding(40px, 40px);
  color: $textColor;
}
