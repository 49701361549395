@mixin codeTextArea {
  color: #7d7d7d;
  font-family: $codeFont;
  font-size: 12px;
  line-height: 17px;
}

// Edit panel in details view, not shown by default.
#edit {
  display: none;
}

.snippet-form {
  box-sizing: border-box;
  background-color: $bgColor;

  // Desktop Grid
  @media only screen and (min-width: 700px) {
    padding: 15px $boxPadding;
    display: grid;
    grid-template: "a b c d" "e e e e";
    grid-template-columns: 1fr 1fr 1fr 10fr;
    align-items: center;

    .form-textarea {
      height: 70vh;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-top: 1px solid $borderColor;
      margin-top: 15px;
      padding-top: 15px;
    }
    .form-action {
      justify-self: end;
    }
  }

  // Mobile Grid
  @media only screen and (max-width: 699px) {
    padding: 10px;
    display: grid;
    justify-content: space-between;
    grid-template: "a b" "c c" "e e" "d d";
    align-items: center;
    .form-expire select {
      margin-right: 0;
    }
    .form-action {
      justify-self: start;
    }

    p {
      padding: 4px 0;
    }
  }

  // ⬇ Layout
  .form-lexer {
    grid-area: a;
  }
  .form-expire {
    grid-area: b;
  }
  .form-rtl {
    grid-area: c;
    white-space: nowrap;
  }
  // .form-action { grid-area: d; }
  .form-textarea {
    grid-area: e;
  }

  .form-action {
    grid-area: d;
    .btn {
      width: auto;
      padding: 6px 20px;
    }
  }

  // ⬇ Form Element Appearance
  label {
    display: none;
    font-size: 13px;
  }

  .form-rtl label {
    display: inline;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;

    padding: 5px 7px;
    margin-right: 15px;
    min-width: 160px;

    color: $selectTextColor;
    background-color: $selectBgColor;

    border: 1px solid $selectBorderColor;
    border-radius: 3px;

    font-family: $baseFont;
    font-weight: $baseFontRegular;
    font-size: 14px;

    cursor: pointer;

    // Triangle
    background-image: linear-gradient(
        45deg,
        transparent 50%,
        $selectTriangleColor 50%
      ),
      linear-gradient(135deg, $selectTriangleColor 50%, transparent 50%);
    background-position:
      calc(100% - 18px) calc(13px),
      calc(100% - 13px) calc(13px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      3px 1.5em;
    background-repeat: no-repeat;

    &:hover {
      border-color: darken($selectBorderColor, 10%);
    }
  }

  textarea {
    padding: 20px;
    @include codeTextArea;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px solid $borderColor;

    &:active,
    &:focus {
      border-color: darken($selectBorderColor, 10%);
    }
  }
}
