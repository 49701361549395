html,
body,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

%reset {
  margin: 0;
  padding: 0;
}
