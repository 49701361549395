.snippet-diff {
  color: $codeTextColor;
  background-color: $codeBgColor;

  h2 {
    margin: 0;
    @include boxPadding(20px, 20px);

    color: $codeTextColor;
    font-weight: $baseFontDemiBold;
    font-size: 14px;
  }

  .snippet-code {
    @include boxPadding(10px, 10px);

    background-color: $codeDiffBgColor;
    white-space: pre;
    font-size: 12px;
  }
}

.snippet-code {
  @include boxPadding(20px, 20px);

  font-family: $codeFont;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  //overflow: auto;

  color: $codeTextColor;
  background-color: $codeBgColor;

  &.wordwrap {
    overflow: auto;
    li {
      white-space: pre-wrap !important;
    }
  }

  ol {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    counter-reset: lineNumberCounter;

    li {
      margin: 0;
      padding: 0;
      white-space: pre;
      padding-left: 50px;

      &:before {
        color: $codeLineNumberColor;
        counter-increment: lineNumberCounter;
        content: counter(lineNumberCounter);
        text-align: right;

        width: 30px;
        position: absolute;
        display: inline-block;
        left: 0px;
      }
    }

    li.marked {
      background-color: $markerBgColor;
      &:before {
        color: $markerLineNumberColor;
      }
    }
  }

  @mixin diffline {
    display: inline-block;
    width: 100%;
    margin: 0 -10px;
    padding: 0 10px;
  }
  // Pygments
  .gd {
    background-color: #473335;
    color: #f8f8f2;
    @include diffline;
  }
  .gi {
    background-color: #2d4a39;
    color: #f8f8f2;
    @include diffline;
  }

  .hll {
    background-color: #49483e;
  }
  .c {
    color: #75715e;
  } /* Comment */
  .err {
    color: #960050;
    background-color: #1e0010;
  } /* Error */
  .k {
    color: #66d9ef;
  } /* Keyword */
  .l {
    color: #ae81ff;
  } /* Literal */
  .n {
    color: #f8f8f2;
  } /* Name */
  .o {
    color: #f92672;
  } /* Operator */
  .p {
    color: #f8f8f2;
  } /* Punctuation */
  .cm {
    color: #75715e;
  } /* Comment.Multiline */
  .cp {
    color: #75715e;
  } /* Comment..codeproc */
  .c1 {
    color: #75715e;
  } /* Comment.Single */
  .cs {
    color: #75715e;
  } /* Comment.Special */
  .ge {
    font-style: italic;
  } /* Generic.Emph */
  .gs {
    font-weight: bold;
  } /* Generic.Strong */
  .kc {
    color: #66d9ef;
  } /* Keyword.Constant */
  .kd {
    color: #66d9ef;
  } /* Keyword.Declaration */
  .kn {
    color: #f92672;
  } /* Keyword.Namespace */
  .kp {
    color: #66d9ef;
  } /* Keyword.Pseudo */
  .kr {
    color: #66d9ef;
  } /* Keyword.Reserved */
  .kt {
    color: #66d9ef;
  } /* Keyword.Type */
  .ld {
    color: #e6db74;
  } /* Literal.Date */
  .m {
    color: #ae81ff;
  } /* Literal.Number */
  .s {
    color: #e6db74;
  } /* Literal.String */
  .na {
    color: #a6e22e;
  } /* Name.Attribute */
  .nb {
    color: #f8f8f2;
  } /* Name.Builtin */
  .nc {
    color: #a6e22e;
  } /* Name.Class */
  .no {
    color: #66d9ef;
  } /* Name.Constant */
  .nd {
    color: #a6e22e;
  } /* Name.Decorator */
  .ni {
    color: #f8f8f2;
  } /* Name.Entity */
  .ne {
    color: #a6e22e;
  } /* Name.Exception */
  .nf {
    color: #a6e22e;
  } /* Name.Function */
  .nl {
    color: #f8f8f2;
  } /* Name.Label */
  .nn {
    color: #f8f8f2;
  } /* Name.Namespace */
  .nx {
    color: #a6e22e;
  } /* Name.Other */
  .py {
    color: #f8f8f2;
  } /* Name.Property */
  .nt {
    color: #f92672;
  } /* Name.Tag */
  .nv {
    color: #f8f8f2;
  } /* Name.Variable */
  .ow {
    color: #f92672;
  } /* Operator.Word */
  .w {
    color: #f8f8f2;
  } /* Text.Whitespace */
  .mf {
    color: #ae81ff;
  } /* Literal.Number.Float */
  .mh {
    color: #ae81ff;
  } /* Literal.Number.Hex */
  .mi {
    color: #ae81ff;
  } /* Literal.Number.Integer */
  .mo {
    color: #ae81ff;
  } /* Literal.Number.Oct */
  .sb {
    color: #e6db74;
  } /* Literal.String.Backtick */
  .sc {
    color: #e6db74;
  } /* Literal.String.Char */
  .sd {
    color: #e6db74;
  } /* Literal.String.Doc */
  .s2 {
    color: #e6db74;
  } /* Literal.String.Double */
  .se {
    color: #ae81ff;
  } /* Literal.String.Escape */
  .sh {
    color: #e6db74;
  } /* Literal.String.Heredoc */
  .si {
    color: #e6db74;
  } /* Literal.String.Interpol */
  .sx {
    color: #e6db74;
  } /* Literal.String.Other */
  .sr {
    color: #e6db74;
  } /* Literal.String.Regex */
  .s1 {
    color: #e6db74;
  } /* Literal.String.Single */
  .ss {
    color: #e6db74;
  } /* Literal.String.Symbol */
  .bp {
    color: #f8f8f2;
  } /* Name.Builtin.Pseudo */
  .vc {
    color: #f8f8f2;
  } /* Name.Variable.Class */
  .vg {
    color: #f8f8f2;
  } /* Name.Variable.Global */
  .vi {
    color: #f8f8f2;
  } /* Name.Variable.Instance */
  .il {
    color: #ae81ff;
  } /* Literal.Number.Integer.Long */
}
