.snippet-text {
  background-color: $bgColor;
}

// Generic content pages such as About/404/500
// and base styles for text based lexer.
article {
  @include boxPadding(30px, 40px);

  font-size: 16px;
  font-weight: $baseFontRegular;
  line-height: 24px;
  word-break: break-word;

  color: $textColor;

  @include colored-links;

  .first-item {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $baseFontRegular;
    line-height: 1.3em;
  }

  p {
    margin: 10px 0 20px 0;
  }

  strong,
  b {
    font-weight: $baseFontDemiBold;
  }

  table {
    margin: 20px 0;
    border: 1px solid $borderColor;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid $borderColor;
      padding: 5px 10px;
    }
  }

  hr {
    border: 0;
    height: 1px;
    background-color: darken($borderColor, 10%);
  }

  pre {
    font-family: $codeFont;
    font-size: 13px;
    font-weight: 300;

    background-color: #f8f8f8;
    padding: 10px;
  }

  blockquote {
    font-style: italic;
  }

  dl,
  ul,
  ol,
  table {
    p {
      margin: 0;
    }
  }
}

// Specific styles for text based lexer
.snippet-text article {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-bottom: 5px;
    border-bottom: 1px solid $borderColor;
  }

  // rst syntax
  .admonition {
    padding: 10px 10px;
    margin: 20px 0;
    background-color: $metaBgColor;

    .admonition-title {
      margin: 0;
      font-weight: $baseFontBold;
    }

    p {
      margin: 20px 0 0 0;
    }
  }

  // rst syntax
  .problematic {
    background-color: $confirmBgColor;
  }

  // rst syntax
  .system-message {
    background-color: $confirmBgColor;
    border: 2px dashed darken($confirmBgColor, 30%);
    padding: 10px 20px;
    margin: 20px 0;
    p {
      margin: 10px 0;
    }
  }
}
