body {
  font-family: $baseFont;
  font-weight: $baseFontRegular;

  // By default, all pages have a dark background. Since
  // most pages have 'code' snippets, that allows the code
  // that is out of canvas to be visible (scroll right).
  background-color: $codeBgColor;
}

// But pure text/content pages have a white background.
// With that, pages which are shorter than the browser
// window, won't have a dark stripe at the bottom.
body[code-page] {
  background-color: $bgColor;
}
body[text-page] {
  background-color: $bgColor;
}

// Platform is set by Javascript.
body[data-platform="win"] .platform-mac {
  display: none;
}
body[data-platform="mac"] .platform-win {
  display: none;
}

.btn {
  padding: 6px 0;
  position: relative;

  display: inline-block;

  color: $btnTextColor;
  background-color: $btnBgColor;

  border: 1px solid $btnBorderColor;
  border-radius: 3px;

  font-size: 13px;
  font-weight: $baseFontDemiBold;

  text-decoration: none;
  text-align: center;

  cursor: pointer;

  .sep {
    @include separator();
  }

  &:hover {
    text-decoration: none;
    background-color: darken($btnBgColor, 5%);
  }

  &:active {
    top: 1px;
  }
}

.confirm-modal {
  @include colored-links;
  background-color: $confirmBgColor;
  color: $confirmTextColor;

  // Hidden by default
  overflow: hidden;
  max-height: 0;

  // Foldout animation
  transition: max-height 0.15s ease-in;

  form {
    @include boxPadding(20px, 20px);
    display: block;
  }

  .btn {
    padding: 6px 15px;
    margin: 0 10px;
  }

  .no {
    font-size: 13px;
  }

  &:target {
    max-height: 80px;
  }
}
